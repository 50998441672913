export const logoNegative = ['608 134', ` 
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="64" stlyle="width:100%">
<path d="M0 0 C10 0 10 0 12.625 1.625 C14.43667679 4.75425992 14.66713166 6.44196446 14 10 C11.5 12.375 11.5 12.375 9 14 C10.65 15.98 12.3 17.96 14 20 C14.38671875 19.2265625 14.7734375 18.453125 15.171875 17.65625 C18.11458333 11.77083333 21.05729167 5.88541667 24 0 C24.66 0 25.32 0 26 0 C27.67275302 3.62222211 29.33716251 7.2482166 31 10.875 C31.47695312 11.90753906 31.95390625 12.94007812 32.4453125 14.00390625 C32.89648438 14.99003906 33.34765625 15.97617187 33.8125 16.9921875 C34.44091797 18.35899658 34.44091797 18.35899658 35.08203125 19.75341797 C36 22 36 22 36 24 C34.35 24 32.7 24 31 24 C30.34 21.69 29.68 19.38 29 17 C24.91487319 17.11522262 24.91487319 17.11522262 21 18 C19.19970518 20.93355547 19.19970518 20.93355547 18 24 C16.125 25.0625 16.125 25.0625 14 25 C9.90048741 23.15521934 7.62352368 20.59519912 5 17 C4.67 19.31 4.34 21.62 4 24 C2.68 24 1.36 24 0 24 C0 16.08 0 8.16 0 0 Z M4 4 C4 5.98 4 7.96 4 10 C5.65 10 7.3 10 9 10 C10.01111093 6.95086772 10.01111093 6.95086772 9 4 C7.35 4 5.7 4 4 4 Z M24 8 C23.34 9.98 22.68 11.96 22 14 C23.65 14 25.3 14 27 14 C26.34 12.02 25.68 10.04 25 8 C24.67 8 24.34 8 24 8 Z " fill="#E8C636" transform="translate(52,12)"/>
<path d="M0 0 C3.34745357 2.506624 4.81171895 5.36455147 6 9.375 C6 13.95349687 4.83174431 16.82362297 3 21 C3.66 21.66 4.32 22.32 5 23 C5 23.99 5 24.98 5 26 C0.26315789 25.07894737 0.26315789 25.07894737 -1.875 24.4375 C-4.20016209 23.8712531 -4.20016209 23.8712531 -7.4375 24.125 C-12.50204384 23.94729671 -14.66771656 22.22426046 -18.3125 18.875 C-20.67456307 14.8507444 -20.77981025 11.5582723 -20 7 C-18.13097505 2.9629061 -16.5755775 1.36114667 -12.8125 -1 C-7.97192054 -2.26966019 -4.57710699 -2.02284129 0 0 Z M-16 8 C-16.62601538 11.96476406 -16.65946039 13.99762021 -14.4375 17.375 C-11.3214771 19.4523486 -8.69536586 19.71574109 -5 20 C-5.309375 19.38125 -5.61875 18.7625 -5.9375 18.125 C-6.625 16.75 -7.3125 15.375 -8 14 C-7.236875 14.33 -6.47375 14.66 -5.6875 15 C-3.00930865 16.17436686 -3.00930865 16.17436686 0 16 C1.36222307 14.84983746 1.36222307 14.84983746 1.3125 11.75 C1.2395692 8.01073677 1.2395692 8.01073677 -0.5 5.0625 C-3.36046904 2.70261304 -5.32366903 2.26338926 -9.02734375 2.51953125 C-12.55479057 3.37869156 -14.00100675 5.06397866 -16 8 Z " fill="#E9C635" transform="translate(43,13)"/>
<path d="M0 0 C1.0209375 0.9590625 1.0209375 0.9590625 2.0625 1.9375 C1.4025 3.2575 0.7425 4.5775 0.0625 5.9375 C-0.4325 5.4425 -0.9275 4.9475 -1.4375 4.4375 C-5.16395843 2.20162494 -7.61661757 2.52598739 -11.9375 2.9375 C-14.10416667 3.77083333 -14.10416667 3.77083333 -14.9375 5.9375 C-15.58669671 11.52987177 -15.58669671 11.52987177 -13.3125 16.4375 C-10.25343299 18.36954232 -8.4947204 18.46729878 -4.9375 17.9375 C-2.90082385 16.39243534 -2.07676922 15.21603844 -0.9375 12.9375 C-2.9175 12.9375 -4.8975 12.9375 -6.9375 12.9375 C-6.9375 11.6175 -6.9375 10.2975 -6.9375 8.9375 C-3.3075 8.9375 0.3225 8.9375 4.0625 8.9375 C3.69444444 16.17592593 3.69444444 16.17592593 0.625 19.875 C-3.46640595 23.16808283 -6.7800043 23.49292261 -11.9375 22.9375 C-15.60681783 21.30669208 -18.0209248 19.48316411 -19.9375 15.9375 C-21.32169623 9.90635931 -21.09044076 6.48232685 -17.9375 0.9375 C-12.66566082 -4.16427985 -5.9864985 -4.20328618 0 0 Z " fill="#E9C635" transform="translate(117.9375,14.0625)"/>
<path d="M0 0 C3.14854575 2.39289477 4.72475231 5.19145992 6 8.9375 C6 13.95918699 4.20278203 17.57169634 1 21.375 C-2.75745864 24.42793515 -6.31848701 24.39012608 -11 24 C-15.33570439 22.52514973 -17.56688902 20.66935333 -20.0625 16.8125 C-21.24710635 11.99510086 -21.03080591 8.53744919 -19 4 C-13.70204025 -2.51818955 -7.41324264 -2.89393964 0 0 Z M-14.5625 5.0625 C-16.35608261 8.72764707 -16.58674844 10.9661045 -16 15 C-13.84524379 18.27522945 -12.3358577 18.90229594 -8.5625 20 C-4.98594635 20.27464857 -4.98594635 20.27464857 -2.125 18.5 C0.6350094 15.25293011 1 13.23692038 1 9 C-0.13650944 6.62366207 -1.14441029 4.85558971 -3 3 C-7.54457219 2.35472606 -10.90602637 2.11948464 -14.5625 5.0625 Z " fill="#E8C636" transform="translate(144,13)"/>
<path d="M0 0 C12 0 12 0 17 3 C19.93972148 6.0426439 20.43169 8.2484929 20.4375 12.4375 C20.34048824 16.26180579 20.13604107 17.77326488 18.125 21.125 C13.00899725 25.63912007 6.52249167 24.05929538 0 24 C0 16.08 0 8.16 0 0 Z M4 4 C4 9.28 4 14.56 4 20 C11.49152482 20.73960026 11.49152482 20.73960026 15 18 C16.03467381 15.00767631 16.26057915 12.14866478 16 9 C14.1866503 5.7698823 14.1866503 5.7698823 11 4 C8.69 4 6.38 4 4 4 Z " fill="#EDC833" transform="translate(167,12)"/>
<path d="M0 0 C1.32 0 2.64 0 4 0 C4 6.6 4 13.2 4 20 C6.64 20 9.28 20 12 20 C12 21.32 12 22.64 12 24 C10.02145742 24.19397476 8.0421352 24.38001321 6.0625 24.5625 C4.96035156 24.66691406 3.85820312 24.77132812 2.72265625 24.87890625 C1.82417969 24.91886719 0.92570313 24.95882812 0 25 C-1.68516954 23.31483046 -1.13008019 21.21232212 -1.1328125 18.90625 C-1.13474609 17.36130859 -1.13474609 17.36130859 -1.13671875 15.78515625 C-1.13285156 14.70105469 -1.12898437 13.61695313 -1.125 12.5 C-1.12886719 11.41589844 -1.13273438 10.33179688 -1.13671875 9.21484375 C-1.13542969 8.18488281 -1.13414062 7.15492187 -1.1328125 6.09375 C-1.13168457 5.14177734 -1.13055664 4.18980469 -1.12939453 3.20898438 C-1 1 -1 1 0 0 Z " fill="#E2C23A" transform="translate(153,12)"/>
<path d="M0 0 C1.98 0.495 1.98 0.495 4 1 C4 8.59 4 16.18 4 24 C3.34 24.33 2.68 24.66 2 25 C1.01 24.67 0.02 24.34 -1 24 C-1.02887236 20.18751574 -1.04675492 16.3750593 -1.0625 12.5625 C-1.07087891 11.47388672 -1.07925781 10.38527344 -1.08789062 9.26367188 C-1.09111328 8.22919922 -1.09433594 7.19472656 -1.09765625 6.12890625 C-1.10289307 5.17056885 -1.10812988 4.21223145 -1.11352539 3.22485352 C-1 1 -1 1 0 0 Z " fill="#E2C239" transform="translate(16,12)"/>
<path d="M0 0 C3.3 0 6.6 0 10 0 C10 0.33 10 0.66 10 1 C7.03 1 4.06 1 1 1 C1 8.26 1 15.52 1 23 C4.96 23 8.92 23 13 23 C13.28875 21.205625 13.5775 19.41125 13.875 17.5625 C14.3671875 14.50390625 14.3671875 14.50390625 15 12 C15.66 11.67 16.32 11.34 17 11 C17.33 12.65 17.66 14.3 18 16 C18.33 13.03 18.66 10.06 19 7 C19.33 7 19.66 7 20 7 C20.62757528 16.9540412 20.62757528 16.9540412 18.125 21.125 C13.00899725 25.63912007 6.52249167 24.05929538 0 24 C0 16.08 0 8.16 0 0 Z " fill="#E2C239" transform="translate(167,12)"/>
<path d="M0 0 C0 0.66 0 1.32 0 2 C0.99 2.33 1.98 2.66 3 3 C2.67 4.32 2.34 5.64 2 7 C2 6.34 2 5.68 2 5 C-2.455 4.01 -2.455 4.01 -7 3 C-6.649375 4.258125 -6.29875 5.51625 -5.9375 6.8125 C-4.67863974 12.34092129 -5.22980218 17.43167792 -6 23 C-6.66 23 -7.32 23 -8 23 C-8 15.74 -8 8.48 -8 1 C-3 0 -3 0 0 0 Z " fill="#F1CA31" transform="translate(61,12)"/>
<path d="M0 0 C1.4540625 0.0309375 1.4540625 0.0309375 2.9375 0.0625 C3.2675 1.0525 3.5975 2.0425 3.9375 3.0625 C2.2875 3.0625 0.6375 3.0625 -1.0625 3.0625 C-1.3925 4.3825 -1.7225 5.7025 -2.0625 7.0625 C-1.4025 6.7325 -0.7425 6.4025 -0.0625 6.0625 C-0.0625 5.4025 -0.0625 4.7425 -0.0625 4.0625 C1.5875 4.0625 3.2375 4.0625 4.9375 4.0625 C4.6075 5.7125 4.2775 7.3625 3.9375 9.0625 C1.9575 9.3925 -0.0225 9.7225 -2.0625 10.0625 C-3.625 8.375 -3.625 8.375 -5.0625 6.0625 C-4.50710295 0.09198169 -4.50710295 0.09198169 0 0 Z " fill="#EAC731" transform="translate(18.0625,40.9375)"/>
<path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C2.4140625 3.06640625 2.4140625 3.06640625 2.625 5.5625 C2.69976562 6.38878906 2.77453125 7.21507812 2.8515625 8.06640625 C2.90054688 8.70449219 2.94953125 9.34257813 3 10 C2.34 10 1.68 10 1 10 C0.67 9.34 0.34 8.68 0 8 C-3.60992379 8.65585189 -3.60992379 8.65585189 -7 10 C-6.67 7.03 -6.34 4.06 -6 1 C-3 2 -3 2 -2 4 C-1.34 2.68 -0.68 1.36 0 0 Z " fill="#EAC731" transform="translate(132,41)"/>
<path d="M0 0 C2.33944736 0.28730055 4.6739143 0.61936779 7 1 C7.75 3.25 7.75 3.25 8 6 C6.375 8.375 6.375 8.375 4 10 C1.25 9.75 1.25 9.75 -1 9 C-1.09765625 2.84765625 -1.09765625 2.84765625 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z M2 2 C2 3.65 2 5.3 2 7 C2.99 7 3.98 7 5 7 C4.67 5.35 4.34 3.7 4 2 C3.34 2 2.68 2 2 2 Z " fill="#EAC730" transform="translate(168,41)"/>
<path d="M0 0 C2 2 2 2 2.3125 5 C2.1578125 6.485 2.1578125 6.485 2 8 C-1 10 -1 10 -3.5625 9.75 C-6 9 -6 9 -8 7 C-7.75 3.9375 -7.75 3.9375 -7 1 C-4.48718205 -0.25640898 -2.78302919 -0.20872719 0 0 Z M-4 2 C-4.33 3.65 -4.66 5.3 -5 7 C-3.35 6.67 -1.7 6.34 0 6 C-0.33 4.68 -0.66 3.36 -1 2 C-1.99 2 -2.98 2 -4 2 Z " fill="#EAC730" transform="translate(147,41)"/>
<path d="M0 0 C2 2 2 2 2.3125 5 C2.1578125 6.485 2.1578125 6.485 2 8 C-1 10 -1 10 -3.5625 9.75 C-6 9 -6 9 -8 7 C-7.75 3.9375 -7.75 3.9375 -7 1 C-4.48718205 -0.25640898 -2.78302919 -0.20872719 0 0 Z M-4 2 C-4.33 3.65 -4.66 5.3 -5 7 C-3.35 6.67 -1.7 6.34 0 6 C-0.33 4.68 -0.66 3.36 -1 2 C-1.99 2 -2.98 2 -4 2 Z " fill="#EAC730" transform="translate(35,41)"/>
<path d="M0 0 C2.875 -0.125 2.875 -0.125 6 0 C8 2 8 2 8.3125 5 C8.1578125 6.485 8.1578125 6.485 8 8 C5 10 5 10 2.3125 9.625 C1.549375 9.41875 0.78625 9.2125 0 9 C0 6.03 0 3.06 0 0 Z M3 2 C3 3.65 3 5.3 3 7 C3.99 6.67 4.98 6.34 6 6 C6 5.01 6 4.02 6 3 C5.01 2.67 4.02 2.34 3 2 Z " fill="#ECC830" transform="translate(93,41)"/>
<path d="M0 0 C1.32 0 2.64 0 4 0 C4.33 0.99 4.66 1.98 5 3 C4.67 3.66 4.34 4.32 4 5 C4.66 5 5.32 5 6 5 C6 6.65 6 8.3 6 10 C4.02 10 2.04 10 0 10 C-1.125 8.375 -1.125 8.375 -2 6 C-1.125 2.75 -1.125 2.75 0 0 Z " fill="#E9C631" transform="translate(74,41)"/>
<path d="M0 0 C2.875 -0.125 2.875 -0.125 6 0 C6.66 0.66 7.32 1.32 8 2 C7.625 5.625 7.625 5.625 7 9 C5.0085944 9.38133299 3.00720923 9.71325582 1 10 C0 9 0 9 -0.09765625 6.93359375 C-0.08605469 6.10988281 -0.07445312 5.28617188 -0.0625 4.4375 C-0.05347656 3.61121094 -0.04445312 2.78492188 -0.03515625 1.93359375 C-0.02355469 1.29550781 -0.01195312 0.65742187 0 0 Z M2 2 C2 3.65 2 5.3 2 7 C3.32 6.67 4.64 6.34 6 6 C5.34 4.68 4.68 3.36 4 2 C3.34 2 2.68 2 2 2 Z " fill="#EBC730" transform="translate(52,41)"/>
<path d="M0 0 C4 2 4 2 5 3 C5.33 2.01 5.66 1.02 6 0 C6.66 0 7.32 0 8 0 C8 2.97 8 5.94 8 9 C4 7 4 7 3 6 C2.67 7.32 2.34 8.64 2 10 C1.34 10 0.68 10 0 10 C0 6.7 0 3.4 0 0 Z " fill="#E8C632" transform="translate(154,41)"/>
<path d="M0 0 C0.33 0 0.66 0 1 0 C1.33 6.27 1.66 12.54 2 19 C4.97 19.33 7.94 19.66 11 20 C11 20.99 11 21.98 11 23 C9.35425434 23.02688151 7.70838587 23.04634123 6.0625 23.0625 C5.14597656 23.07410156 4.22945312 23.08570312 3.28515625 23.09765625 C1 23 1 23 0 22 C-0.08700342 20.36527118 -0.10701063 18.72685945 -0.09765625 17.08984375 C-0.09443359 16.10048828 -0.09121094 15.11113281 -0.08789062 14.09179688 C-0.07951172 13.05087891 -0.07113281 12.00996094 -0.0625 10.9375 C-0.05798828 9.89271484 -0.05347656 8.84792969 -0.04882812 7.77148438 C-0.03699913 5.1809334 -0.02051689 2.59049441 0 0 Z " fill="#F0C931" transform="translate(154,13)"/>
<path d="M0 0 C1.67542976 0.28604898 3.34385343 0.61781233 5 1 C5.625 4.375 5.625 4.375 6 8 C5.34 8.66 4.68 9.32 4 10 C1.375 9.625 1.375 9.625 -1 9 C-0.814375 8.4225 -0.62875 7.845 -0.4375 7.25 C0.04182379 4.78490621 -0.34273163 3.38259782 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z " fill="#E6C533" transform="translate(181,41)"/>
<path d="M0 0 C0.66 0 1.32 0 2 0 C5 6.625 5 6.625 5 10 C4.34 10 3.68 10 3 10 C2.67 9.34 2.34 8.68 2 8 C0.35 8.66 -1.3 9.32 -3 10 C-2.49287967 6.28111761 -1.88391785 3.26545761 0 0 Z " fill="#E8C631" transform="translate(116,41)"/>
<path d="M0 0 C0.99 0 1.98 0 3 0 C3 2.31 3 4.62 3 7 C3.99 7.33 4.98 7.66 6 8 C5.67 8.66 5.34 9.32 5 10 C3.35 9.67 1.7 9.34 0 9 C0 6.03 0 3.06 0 0 Z " fill="#E9C630" transform="translate(42,41)"/>
<path d="M0 0 C3.875 1.75 3.875 1.75 5 4 C5.039992 5.99960012 5.04346799 8.00047242 5 10 C4.01 10.495 4.01 10.495 3 11 C2.67 10.34 2.34 9.68 2 9 C1.01 9.495 1.01 9.495 0 10 C0 8.35 0 6.7 0 5 C0.66 5 1.32 5 2 5 C2 4.34 2 3.68 2 3 C1.34 2.67 0.68 2.34 0 2 C0 1.34 0 0.68 0 0 Z " fill="#E0C13B" transform="translate(61,12)"/>
<path d="M0 0 C0.99 0.33 1.98 0.66 3 1 C3 3.64 3 6.28 3 9 C2.01 9 1.02 9 0 9 C0 6.03 0 3.06 0 0 Z " fill="#E9C632" transform="translate(106,41)"/>
<path d="M0 0 C0.33 0 0.66 0 1 0 C0.25 7.75 0.25 7.75 -2 10 C-2.33 9.34 -2.66 8.68 -3 8 C-3.99 8 -4.98 8 -6 8 C-5.34 6.68 -4.68 5.36 -4 4 C-4.99 3.67 -5.98 3.34 -7 3 C-5.68 3 -4.36 3 -3 3 C-2.67 3.66 -2.34 4.32 -2 5 C-1.34 3.35 -0.68 1.7 0 0 Z " fill="#E1C23B" transform="translate(121,23)"/>
<path d="M0 0 C0.66 0 1.32 0 2 0 C2 2.97 2 5.94 2 9 C0.68 8.34 -0.64 7.68 -2 7 C-2 6.34 -2 5.68 -2 5 C-1.34 5 -0.68 5 0 5 C0 3.35 0 1.7 0 0 Z " fill="#F0CA2C" transform="translate(160,41)"/>
</svg>
`]
